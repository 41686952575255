<template>
    <v-dialog
        v-model="dialog"
        max-width="1000"

    >
        <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    Ocupación

                </v-card-title>

                <v-card-text class="pt-5">
                    <v-simple-table dense>
                        <template v-slot:default>

                            <thead>
                                <tr>
                                    <th class="green lighten-3 text-center"><span class="body-2 font-weight-black">Paciente</span></th>
                                    <th class="green lighten-3 text-center"><span class="body-2 font-weight-black">Motivo</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="mb-3" v-for="item in movimientos" :key="item.id">
                                    <td width="40%" >
                                        <span>({{item.cip}}) {{item.pacienteNombre}}<br/></span>
                                        <span class="caption">del servicio de {{item.servicioNombre}}</span>
                                    </td>
                                    <td width="60%">
                                        {{item.motivo}}
                                    </td>

                                </tr>
                            </tbody>

                        </template>
                    </v-simple-table>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" @click="dialog=false">
                        Cerrar
                    </v-btn>
                </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>
export default {

    props:{
        movimientos:Array,
        dialogMovs:Boolean,

    },

    data:()=>({

    }),
    methods:{

    },

    computed:{
        dialog:{
            get(){
                return this.dialogMovs
            },
            set(value){
                this.$emit('update:dialogMovs',value)
            },
        },
    },

    watch:{

    },

}
</script>

<style>

</style>